'use client'

import { Card, CardContent } from '@mui/material'
import { useRollbar } from '@rollbar/react'
import { useEffect } from 'react'

import Center from '~/components/Center'
import Fallback from '~/layouts/errors/Fallback'

type ErrorProps = {
  error: Error
  reset: () => void
}

export default function Error(props: ErrorProps) {
  // Notify Rollbar of the error
  const rollbar = useRollbar()
  useEffect(() => {
    rollbar.error(props.error)
  }, [props.error, rollbar])

  return (
    <Center.Middle>
      <Card sx={{ maxWidth: 500 }}>
        <CardContent>
          <Fallback {...props} />
        </CardContent>
      </Card>
    </Center.Middle>
  )
}
